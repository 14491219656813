/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Alarm Clock',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M8.50535 4.34845C8.50535 4.0447 8.25911 3.79845 7.95535 3.79845C7.65159 3.79845 7.40535 4.0447 7.40535 4.34845V8.23245C7.40535 8.53621 7.65159 8.78245 7.95535 8.78245H10.9694C11.2731 8.78245 11.5194 8.53621 11.5194 8.23245C11.5194 7.9287 11.2731 7.68245 10.9694 7.68245H8.50535V4.34845Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0855 1.31484C12.5066 1.04515 11.8619 0.949342 11.2296 1.03905C10.6213 1.12535 10.0497 1.37978 9.57881 1.77301C9.06802 1.64719 8.53398 1.58045 7.98435 1.58045C7.43263 1.58045 6.89662 1.6477 6.38406 1.77445C5.91101 1.39099 5.34067 1.14572 4.7361 1.06644C4.11113 0.984479 3.47576 1.08334 2.90523 1.3513C2.3347 1.61927 1.8529 2.04511 1.51691 2.57842C1.18133 3.11106 1.00522 3.72859 1.00935 4.35808C1.0089 5.04839 1.22696 5.71985 1.63031 6.27705C1.44179 6.89292 1.34035 7.54684 1.34035 8.22445C1.34035 10.0938 2.11235 11.7828 3.35492 12.9901L2.29044 14.0545C2.07565 14.2693 2.07565 14.6176 2.29044 14.8324C2.50523 15.0472 2.85347 15.0472 3.06826 14.8324L4.20859 13.692C5.2808 14.4339 6.58183 14.8685 7.98435 14.8685C9.36624 14.8685 10.6496 14.4466 11.7126 13.7246L12.8204 14.8324C13.0352 15.0472 13.3835 15.0472 13.5983 14.8324C13.813 14.6176 13.813 14.2693 13.5983 14.0545L12.573 13.0293C13.8395 11.8195 14.6284 10.1141 14.6284 8.22445C14.6284 7.55775 14.5302 6.914 14.3474 6.30684C14.7634 5.74463 14.9892 5.06248 14.9903 4.36165C15.0014 3.72493 14.8279 3.09858 14.4907 2.55827C14.1526 2.01649 13.6644 1.58452 13.0855 1.31484ZM13.7937 4.99805C13.8573 4.79156 13.8904 4.57535 13.8904 4.35645V4.34602C13.8984 3.92047 13.7829 3.50173 13.5575 3.14065C13.3322 2.77957 13.0068 2.49168 12.621 2.31195C12.2352 2.13221 11.8055 2.06836 11.3841 2.12814C11.2183 2.15167 11.0566 2.19394 10.9018 2.25361C12.1234 2.85164 13.1345 3.81352 13.7937 4.99805ZM2.19473 4.96277C2.85004 3.80206 3.8443 2.85811 5.04315 2.26528C4.89803 2.21372 4.74722 2.17731 4.59308 2.1571C4.17655 2.10248 3.7531 2.16836 3.37286 2.34695C2.99262 2.52554 2.67153 2.80935 2.44759 3.16478C2.22366 3.52021 2.10628 3.93237 2.10934 4.35245L2.10937 4.35721C2.10908 4.56351 2.1382 4.76729 2.19473 4.96277ZM2.44035 8.22445C2.44035 5.16259 4.92248 2.68045 7.98435 2.68045C11.0462 2.68045 13.5284 5.16259 13.5284 8.22445C13.5284 11.2863 11.0462 13.7685 7.98435 13.7685C4.92248 13.7685 2.44035 11.2863 2.44035 8.22445Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path d="M12.533 6.523a.6.6 0 0 0-1.2 0v5.826a.6.6 0 0 0 .6.6h4.521a.6.6 0 1 0 0-1.2h-3.921V6.523z" />
          <path
            fillRule="evenodd"
            d="M19.533 2.176a4.72 4.72 0 0 0-2.657-.395c-.896.127-1.776.517-2.45 1.125a9.76 9.76 0 0 0-2.45-.311c-.849 0-1.672.108-2.457.312-.661-.601-1.567-.97-2.445-1.085a4.72 4.72 0 0 0-4.609 2.165 4.72 4.72 0 0 0-.727 2.549 4.68 4.68 0 0 0 .955 2.837c-.298.934-.459 1.93-.459 2.963 0 2.823 1.201 5.365 3.119 7.144l-1.76 1.76a.6.6 0 0 0 .849.849l1.845-1.844c1.601 1.153 3.565 1.833 5.689 1.833 2.093 0 4.031-.66 5.619-1.783l1.795 1.794a.6.6 0 0 0 .848-.849l-1.703-1.703c1.955-1.782 3.182-4.348 3.182-7.202 0-1.018-.156-1.998-.445-2.92.639-.822.987-1.834.988-2.876a4.72 4.72 0 0 0-2.727-4.364zm1.187 5.862c.222-.467.34-.981.34-1.504 0-.689-.167-1.355-.533-1.943a3.52 3.52 0 0 0-3.483-1.622 3.52 3.52 0 0 0-1.225.414 9.78 9.78 0 0 1 4.901 4.656zM3.256 7.991a9.78 9.78 0 0 1 4.855-4.598 3.52 3.52 0 0 0-3.147-.076 3.52 3.52 0 0 0-1.483 1.31c-.358.569-.547 1.236-.542 1.908a3.48 3.48 0 0 0 .317 1.456zm.179 4.345c0-4.717 3.824-8.541 8.541-8.541s8.541 3.824 8.541 8.541-3.824 8.541-8.541 8.541-8.541-3.824-8.541-8.541z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
