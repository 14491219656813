import React, { useCallback, useMemo, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import {
  GetDashboardChecklistDataQuery,
  GetDashboardStatsDataQuery,
  useGetDashboardChecklistDataQuery,
  useGetDashboardStatsDataQuery,
  useGetRegistryOrdersIdsQuery
} from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useDashboardChecklistTelemetry } from './DashboardChecklist.telemetry';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

type DashboardChecklistContext = {
  toggleBottomSheet?: () => void;
  isChecklistExpanded: boolean;
  toggleChecklist: () => void;
  checklistDataLoading: boolean;
  checklistData?: GetDashboardChecklistDataQuery;
  statsDataLoading: boolean;
  statsData?: GetDashboardStatsDataQuery;
  checklistItemsCompleted: number;
  checklistItemsTotal: number;
  adminDashboardStatsEnabled: boolean;
};

type DashboardChecklistContextProps = {
  eventHandle: string;
};

const [Provider, useDashboardChecklistContext] = createContext<DashboardChecklistContext>({ name: 'DashboardChecklist' });

const DashboardChecklistProvider: React.FC<DashboardChecklistContextProps> = ({ eventHandle, children }) => {
  const adminDashboardStatsEnabled = useFeatureValue('adminDashboardStats').value === 'treatment';
  const telemetry = useDashboardChecklistTelemetry();
  const isMobile = useIsMobileScreen();

  const [isChecklistExpanded, setIsChecklistExpanded] = useState(false);

  const toggleChecklist = useCallback(() => {
    setIsChecklistExpanded(prev => !prev);
  }, []);

  const { loading: checklistDataLoading, data: checklistData } = useGetDashboardChecklistDataQuery({
    batchMode: 'fast',
    variables: {
      eventHandle
    },
    skip: !eventHandle,
    onCompleted: () => {
      telemetry.checklistDataLoaded();

      if (isMobile) return;
      toggleChecklist();
    }
  });
  const { loading: loadingRegistryOrdersIds, data: registryOrdersIds } = useGetRegistryOrdersIdsQuery({
    variables: {
      eventHandle
    },
    batchMode: 'fast'
  });
  const { loading: statsDataLoading, data: statsData } = useGetDashboardStatsDataQuery({
    variables: {
      eventHandle,
      eventId: registryOrdersIds?.eventByName?.id || '',
      getEcardsByEventIdId: registryOrdersIds?.eventByName?.id || '',
      args: {
        relationshipTag: 'GIFT_TRACKER_THANK_YOU',
        externalMessageIds: registryOrdersIds?.eventByName?.registry.orders?.map(order => order.id) || []
      }
    },
    batchMode: 'fast',
    skip: !adminDashboardStatsEnabled || loadingRegistryOrdersIds,
    onCompleted: () => {
      telemetry.statsDataLoaded();
    }
  });

  const [checklistItemsCompleted, checklistItemsTotal] = useMemo(() => {
    if (checklistDataLoading) return [0, 0];

    const items = checklistData?.eventByName?.checklist?.items ?? [];
    const totalItems = items.length;
    const completedItems = items.filter(item => item.completedAt !== null).length;

    return [completedItems, totalItems];
  }, [checklistData, checklistDataLoading]);

  const ctx = useMemo<DashboardChecklistContext>(
    () => ({
      isChecklistExpanded,
      toggleChecklist,
      checklistDataLoading,
      checklistData,
      statsDataLoading,
      statsData,
      checklistItemsCompleted,
      checklistItemsTotal,
      adminDashboardStatsEnabled
    }),
    [
      isChecklistExpanded,
      toggleChecklist,
      checklistDataLoading,
      checklistData,
      statsDataLoading,
      statsData,
      checklistItemsCompleted,
      checklistItemsTotal,
      adminDashboardStatsEnabled
    ]
  );

  return <Provider value={ctx}>{children}</Provider>;
};

export { DashboardChecklistProvider, useDashboardChecklistContext };
