/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Web Globe',
  definitions: {
    '24': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M7.3327 8.73445C9.48639 9.73088 12.5495 10.3806 16.0002 10.3806C19.4509 10.3806 22.514 9.73088 24.6677 8.73445C25.0416 8.56148 25.3761 8.38447 25.6741 8.20551L26.2705 9.30385C23.892 10.7196 20.176 11.6306 16.0002 11.6306C11.8244 11.6306 8.10843 10.7196 5.72998 9.30385L6.32629 8.20551C6.62429 8.38447 6.95884 8.56148 7.3327 8.73445Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 27.75C22.4893 27.75 27.75 22.4893 27.75 16C27.75 9.51065 22.4893 4.25 16 4.25C9.51065 4.25 4.25 9.51065 4.25 16C4.25 22.4893 9.51065 27.75 16 27.75ZM16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4972 24.5449C21.7851 22.4182 22.6223 19.4002 22.6223 16C22.6223 12.5998 21.7851 9.58176 20.4972 7.4551C19.1954 5.30533 17.5679 4.25 15.9998 4.25C14.4318 4.25 12.8043 5.30533 11.5024 7.4551C10.2146 9.58176 9.37744 12.5998 9.37744 16C9.37744 19.4002 10.2146 22.4182 11.5024 24.5449C12.8043 26.6947 14.4318 27.75 15.9998 27.75C17.5679 27.75 19.1954 26.6947 20.4972 24.5449ZM15.9998 29C20.3477 29 23.8723 23.1797 23.8723 16C23.8723 8.8203 20.3477 3 15.9998 3C11.652 3 8.12744 8.8203 8.12744 16C8.12744 23.1797 11.652 29 15.9998 29Z"
            fill="currentColor"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M28.728 16.6249H3.27246V15.3749H28.728V16.6249Z" fill="currentColor" />
          <path fillRule="evenodd" clipRule="evenodd" d="M15.375 28.7276L15.375 3.27203L16.625 3.27203L16.625 28.7276L15.375 28.7276Z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
