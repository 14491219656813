import { Box, Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import React, { useCallback } from 'react';
import { ChecklistToDoMark } from '../ChecklistToDoMark';
import { ChevronRight } from '@withjoy/joykit/icons';
import { animationTransition } from '@shared/utils/animationTransition';
import { ChecklistReminderButton } from '../ChecklistReminderButton';
import { ComponentWithAs } from '@shared/utils/forwardRef';
import { ChecklistItemActions, ChecklistSubItemActions } from '../../DashboardChecklist.types';
import { pxToRem } from '@withjoy/joykit/theme';

export interface ChecklistItemBaseProps {
  parentId?: string;
  id: string;
  title: string;
  subtitle?: Maybe<string>;
  completed: boolean;
  onComplete: (id: string, completedAt: string | null, parentId?: string, action?: ChecklistItemActions | ChecklistSubItemActions) => void;
}

interface ChecklistItemNoDateProps extends ChecklistItemBaseProps {
  withDate?: false;
  action: ChecklistSubItemActions;
  /**
   * Can only be used when `withDate` is not
   */
  icon?: React.ReactNode;
}

export interface ChecklistItemWithDateProps extends ChecklistItemBaseProps {
  withDate: true;
  action: ChecklistItemActions;
  /**
   * Only applies when `withDate` is `true`
   */
  reminderAt: Maybe<string>;
  /**
   * Only applies when `withDate` is `true`
   */
  onChangeReminderDate: (id: string, date: string, action: ChecklistItemActions, initiatedFrom?: 'list' | 'modal') => void;
  icon?: false;
}

type ChecklistItemProps = FlexProps & (ChecklistItemNoDateProps | ChecklistItemWithDateProps);

export const ChecklistItem: ComponentWithAs<'div', ChecklistItemProps> = ({
  parentId,
  id,
  action,
  title,
  subtitle,
  completed,
  onComplete,
  withDate,
  reminderAt,
  onChangeReminderDate,
  icon,
  ...props
}) => {
  const handleCompleted = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onComplete(id, completed ? null : new Date().toISOString(), parentId, action);
    },
    [action, completed, id, onComplete, parentId]
  );

  const handleRemindMe = useCallback(
    (date: string) => {
      if (!withDate) return;

      onChangeReminderDate(id, date, action, 'list');
    },
    [withDate, action, onChangeReminderDate, id]
  );

  return (
    <>
      <Flex
        _lastChild={{
          borderBottomRightRadius: [null, pxToRem(12)],
          borderBottomLeftRadius: [null, pxToRem(12)]
        }}
        borderTop={'1px solid'}
        borderTopColor={'mono3'}
        padding={6}
        alignItems={'center'}
        cursor={'pointer'}
        role={'button'}
        backgroundColor={'white'}
        transition={animationTransition('background-color')}
        width={'100%'}
        __css={{
          '&:hover:not(:has(.checkmark:hover,.checklist-content__date:hover))': {
            backgroundColor: {
              sm: 'mono2'
            }
          },
          '&:hover:not(:has(.checkmark:hover,.checklist-content__date:hover)) .checklist-content__title': {
            color: {
              sm: 'linkHover'
            }
          },
          '&:hover:not(:has(.checkmark:hover)) .checklist-content__icon': {
            opacity: 0,
            transform: 'translate(32px,-13px)'
          },
          '&:active:not(:has(.checkmark:active,.checklist-content__date:active))': {
            backgroundColor: 'mono3'
          },
          '&:active:not(:has(.checkmark:active,.checklist-content__date:active)) .checklist-content__title': {
            color: {
              sm: 'linkActive'
            }
          },
          '&:has(.checkmark:hover) .checklist-content__title': {
            textDecoration: {
              sm: 'line-through'
            }
          },
          '&:has(.checkmark:hover) .checklist-content__subtitle': {
            opacity: {
              sm: 0
            }
          },
          '&:has(.checkmark:hover) .checklist-content__date,&:has(.checkmark:hover) .checklist-content__icon': {
            opacity: {
              sm: 0
            }
          },
          ...(subtitle && {
            '&:has(.checkmark:hover) .checklist-content': {
              transform: {
                sm: 'translateY(13px)'
              }
            }
          }),
          '&:has(.checkmark:hover) .checklist-chevron': {
            opacity: {
              sm: 0
            },
            transform: {
              sm: 'translateY(13px)'
            }
          },
          ...((!reminderAt || (reminderAt && completed)) && {
            '&:hover:not(:has(.checkmark:hover)) .checklist-chevron': {
              opacity: {
                sm: 1
              },
              transform: {
                sm: 'translateY(0)'
              }
            }
          })
        }}
        {...props}
      >
        {/* Checkmark */}
        <Box className={'checkmark'}>
          <ChecklistToDoMark completed={completed} toggleCompleted={handleCompleted} showConfetti={!parentId?.length} />
        </Box>
        {/* Content */}
        <Flex
          flexGrow={1}
          paddingLeft={4}
          className={'checklist-content'}
          transition={animationTransition('transform')}
          transform={(completed || reminderAt) && subtitle ? 'translateY(13px)' : 'translateY(0)'}
          overflow={reminderAt ? 'visible' : 'hidden'}
        >
          <Flex flexDirection={'column'} overflow={'hidden'} flexGrow={1} justifyContent={'center'}>
            <TextV2
              typographyVariant={'hed1'}
              className={'checklist-content__title'}
              textDecoration={completed ? 'line-through' : 'none'}
              transition={animationTransition('color')}
              overflow={'hidden'}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
              fontWeight={'medium'}
            >
              {title}
            </TextV2>
            {subtitle && (
              <TextV2
                typographyVariant={'label2'}
                color={'mono10'}
                className={'checklist-content__subtitle'}
                opacity={completed || reminderAt ? 0 : 1}
                transition={animationTransition('opacity')}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                paddingTop={3}
              >
                {subtitle}
              </TextV2>
            )}
          </Flex>
          {withDate && !completed && (
            <Flex
              alignItems={'center'}
              className={'checklist-content__date'}
              transform={reminderAt ? 'translateY(-13px)' : 'translateY(0)'}
              marginRight={reminderAt ? -7 : 0}
              transition={animationTransition('opacity', 'transform', 'margin-right')}
            >
              <ChecklistReminderButton reminderAt={reminderAt} onChangeReminderDate={handleRemindMe} variant="iconButton" />
            </Flex>
          )}
        </Flex>
        {/* Icon */}
        {icon && !completed && (
          <Box className={'checklist-content__icon'} transition={animationTransition('opacity', 'transform')} transform={'translate(32px,0)'}>
            {icon}
          </Box>
        )}
        {/* Chevron */}
        <Flex
          className={'checklist-chevron'}
          paddingLeft={3}
          opacity={completed || reminderAt || icon ? 0 : 1}
          transition={animationTransition('opacity', 'transform')}
          transform={icon && !completed ? 'translateY(13px)' : completed || reminderAt ? 'translateY(13px)' : 'translateY(0)'}
        >
          <ChevronRight />
        </Flex>
      </Flex>
    </>
  );
};
