/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Design',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M1.941 3.93v13.381a.6.6 0 0 0 .034.199l.063.179a.6.6 0 0 0 .047.101l1.64 2.832a.6.6 0 0 0 .068.095 1.33 1.33 0 0 0 2 0 .6.6 0 0 0 .068-.095l1.64-2.832a.6.6 0 0 0 .047-.101l.063-.179a.6.6 0 0 0 .034-.199V3.93a1.49 1.49 0 0 0-.441-1.059c-.282-.28-.663-.437-1.06-.437H3.441c-.397 0-.778.157-1.06.437A1.49 1.49 0 0 0 1.94 3.93zm1.501-.296c-.081 0-.158.032-.214.088s-.087.131-.087.208v1.892h3.305V3.93a.29.29 0 0 0-.087-.208c-.056-.055-.133-.088-.214-.088H3.441zm3.004 3.388H3.141v10.187l.01.03 1.57 2.711c.021.014.046.021.072.021s.051-.008.072-.021l1.57-2.711.01-.03V7.021zm12.567 4.42c-.088-.044-.311-.125-.559-.001a.6.6 0 0 0-.222.19l-4.279 6.031a.77.77 0 0 0 .639 1.217h4.04c.192 0 .376-.076.512-.211s.214-.321.214-.514v-6.166a.62.62 0 0 0-.344-.545zm-3.601 6.237l2.745-3.869v3.869h-2.745zm5.842-14.947c-.149.036-.285.124-.374.25L8.897 19.86a1.08 1.08 0 0 0-.195.551 1.1 1.1 0 0 0 .519.998 1.09 1.09 0 0 0 .563.158h11.333a.95.95 0 0 0 .942-.941V3.356c0-.161-.066-.319-.176-.436a.66.66 0 0 0-.629-.188zm-.696 14.946h.301V16.19h-.301a.6.6 0 0 1 0-1.2h.301v-1.489h-.301a.6.6 0 0 1 0-1.2h.301v-1.489h-.301a.6.6 0 1 1 0-1.2h.301V8.124h-.301a.6.6 0 1 1 0-1.2h.301v-1.84L10.008 20.367H20.86v-1.489h-.301a.6.6 0 0 1 0-1.2z"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M27.754 4.245a.68.68 0 0 0-.395.262L12.18 25.826c-.131.185-.208.402-.223.629a1.23 1.23 0 0 0 .137.653 1.24 1.24 0 0 0 .456.486 1.25 1.25 0 0 0 .643.18h14.356a1.06 1.06 0 0 0 .747-.307 1.05 1.05 0 0 0 .312-.748V4.904a.68.68 0 0 0-.187-.46.7.7 0 0 0-.666-.199zm-.913 18.883h.516v-2.146h-.516c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h.516v-2.146h-.516c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h.516V14.19h-.516c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h.516v-2.146h-.516c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h.516V6.665l-14.14 19.859h14.139v-2.146h-.516c-.345 0-.625-.28-.625-.625s.28-.625.625-.625zM3.393 6.13v16.902c0 .177.077.389.164.54l2.077 3.577a.63.63 0 0 0 .071.099 1.57 1.57 0 0 0 1.165.526 1.57 1.57 0 0 0 1.165-.526.63.63 0 0 0 .071-.099l2.077-3.577c.088-.151.164-.363.164-.54V6.13a1.75 1.75 0 0 0-.519-1.244 1.77 1.77 0 0 0-1.247-.513H5.159a1.77 1.77 0 0 0-1.247.513 1.75 1.75 0 0 0-.519 1.244zm1.766-.507c-.277 0-.516.241-.516.507v2.303h4.456V6.13c0-.266-.239-.507-.516-.507H5.159zm3.94 4.06H4.643v13.242l.025.07 2.008 3.459c.053.045.124.071.195.071s.142-.026.195-.071l2.008-3.459.025-.07V9.683zm9.981 12.814l4.005-5.629c.209-.294.641-.346.924-.125a.63.63 0 0 1 .244.495v5.756a.74.74 0 0 1-.219.526c-.14.139-.328.216-.524.216h-3.781c-.141.002-.28-.034-.403-.105a.8.8 0 0 1-.391-.729c.009-.146.06-.287.145-.406zm1.553-.008h2.381v-3.347l-2.381 3.347z"
            fillRule="evenodd"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M42.074 6.709c-.118-.035-.244-.039-.364-.01s-.231.089-.321.174a.6.6 0 0 0-.076.087L18.547 38.937c-.321.454-.363 1.079-.106 1.572a1.55 1.55 0 0 0 1.347.818h21.536c.33 0 .648-.13.883-.363s.368-.55.368-.882V7.359c0-.045-.005-.09-.015-.134a.7.7 0 0 0-.173-.324c-.085-.09-.193-.156-.311-.192zm-1.812 28.324h1.112v-3.894h-1.112a.6.6 0 1 1 0-1.2h1.112v-3.894h-1.112a.6.6 0 0 1 0-1.2h1.112V20.95h-1.112a.6.6 0 0 1 0-1.2h1.112v-3.894h-1.112a.6.6 0 0 1 0-1.2h1.112v-5.71L19.527 39.63c-.067.095-.075.222-.022.324s.167.172.287.173l21.582-.045v-3.849h-1.112a.6.6 0 1 1 0-1.2zM7.739 8.1a1.12 1.12 0 0 0-.788.323 1.09 1.09 0 0 0-.324.775v3.792h7.359V9.198a1.09 1.09 0 0 0-.324-.775 1.12 1.12 0 0 0-.788-.323H7.739zM5.428 9.198v25.353a.6.6 0 0 0 .034.2l.12.34a.6.6 0 0 0 .047.102l3.115 5.366c.02.034.043.066.069.095.187.212.417.382.675.498s.537.176.82.176.562-.06.82-.176.488-.286.675-.498c.026-.03.049-.061.069-.095l3.115-5.366c.019-.032.034-.066.047-.102l.12-.34a.6.6 0 0 0 .034-.2V9.198a2.29 2.29 0 0 0-.679-1.627 2.32 2.32 0 0 0-1.633-.671H7.739a2.32 2.32 0 0 0-1.633.671 2.29 2.29 0 0 0-.679 1.627zm8.559 4.992H6.628v20.258l.067.19 3.053 5.259c.067.066.145.121.232.16.102.046.214.07.327.07s.224-.024.327-.07c.087-.039.165-.093.232-.16l3.053-5.259.067-.19V14.19zm23.097 8.97c-.273-.136-.624-.064-.803.188l-8.13 11.428a.92.92 0 0 0-.062.971c.082.152.204.277.352.363s.311.127.478.124h7.68a.84.84 0 0 0 .592-.244c.158-.157.248-.37.248-.594V23.712c0-.236-.146-.449-.355-.552zM29.44 35.033l6.798-9.556v9.556H29.44z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
