import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';
import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledExpandableChecklist = styled(Box)`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  transition: ${animationTransition('grid-template-rows', 'opacity')};
  .expanded & {
    grid-template-rows: 1fr;
    opacity: 1;
  }
`;

export const StyledParticleJoyLogo = styled(ParticleJoyLogo)`
  padding: 0;
  cursor: default;
  svg,
  canvas {
    height: 56px;
    width: inherit;
  }
`;

export const ChecklistCardContainer = styled(Flex)``;
const checklistCardContainerStyles: StyleSystemProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.03), 0px 10px 60px -20px rgba(45, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04)',
  backgroundColor: 'white',
  borderRadius: pxToRem(12),
  width: '100%',
  maxWidth: [null, null, pxToRem(375)],
  position: 'relative',
  flexDirection: 'column'
};

export const styles = {
  checklistCardContainer: checklistCardContainerStyles
};
