/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Rsvp',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.705 9.391a.6.6 0 1 0-.911-.781l-2.578 3.008-1.042-1.042a.6.6 0 0 0-.848.849l1.5 1.5a.6.6 0 0 0 .88-.034l3-3.5z"
            fill="currentColor"
          ></path>{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.171 3.541a2.6 2.6 0 0 0-3.309.095L3.788 8.951a2.6 2.6 0 0 0-.888 1.957v9.012a1.6 1.6 0 0 0 1.6 1.6h15a1.6 1.6 0 0 0 1.6-1.6v-7l-.005-1.947a2.6 2.6 0 0 0-1.003-2.046l-6.921-5.387zm-2.519.998a1.4 1.4 0 0 1 1.782-.051l6.921 5.387a1.4 1.4 0 0 1 .54 1.102l.004 1.696-4.399 4.399-1.662-1.662a2.6 2.6 0 0 0-3.677 0L8.5 17.072l-4.4-4.4v-1.764a1.4 1.4 0 0 1 .478-1.054l6.074-5.315zm5.696 13.381l3.551-3.551v5.551a.4.4 0 0 1-.4.4h-.751l-2.4-2.4zm.703 2.4H6.948l4.062-4.061a1.4 1.4 0 0 1 1.98 0l4.062 4.061zm-9.4-2.4l-2.4 2.4H4.5a.4.4 0 0 1-.4-.4v-5.551l3.551 3.551z"
            fill="currentColor"
          ></path>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
