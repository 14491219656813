import React, { useMemo } from 'react';
import { StyledProgressBar, StyledProgressBarFill, styles } from './styles';
import { BoxProps } from '@withjoy/joykit';

interface ProgressBarProps
  extends Readonly<{
    progressPercentage: number;
    height?: number;
    backgroundColor?: BoxProps['color'];
    fillColor?: BoxProps['color'];
  }> {}

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const { progressPercentage, height = 3, backgroundColor, fillColor } = props;

  const controlledPercentage = useMemo(() => {
    if (progressPercentage < 0) return 0;
    if (progressPercentage > 100) return 100;
    return progressPercentage;
  }, [progressPercentage]);

  return (
    <StyledProgressBar __css={styles.progressBar(height, backgroundColor)}>
      {/* inline style is set to update the width without the creation of a new style rule */}
      <StyledProgressBarFill __css={styles.progressBarFill(height, fillColor)} style={{ width: `${controlledPercentage}%` }} />
    </StyledProgressBar>
  );
};
